import React from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import { Container, Header, Body } from './styles/SidebarCard.styled';

const SidebarHelpCard = () => (
  <Container className="sticky">
    <Header>
      <p className="heading">Need more help?</p>
      <Body>
        Feel free to reach out to us by clicking the button below or the Chat
        icon in the bottom right of your screen.
      </Body>
    </Header>
    <CallToAction
      className="sidebar-demo-cta"
      link="#chat-to-us"
      variant="primary"
      size="lg"
      value="Chat With Us"
    />
  </Container>
);

export default SidebarHelpCard;
